import React from 'react';
import LayoutSecondary from '../components/layout/LayoutSecondary';
import CompanyAddress from '../components/CompanyAddress';
import Card from '../components/Card';

const Success = () => (
  <LayoutSecondary>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <h1 className="blocktext-4xl lg:text-5xl xl:text-6xl font-bold leading-none w-full">
          Privacy policy
        </h1>
        <div className="">
          <div className="md:px-10 text-sm">
            <h3 className=""><strong>PunchIn Privacy Policy</strong></h3>
            <div className="">
               <div id="" className="">
                  <h5 className="">OUR COMMITMENT TO YOUR PRIVACY &amp; SECURITY</h5>
                  <p className=""><strong>Updated January 12, 2022</strong></p>
                  <p>The privacy and the security of your information are important to us. This privacy policy (“Privacy Policy”) explains the type of information (“Information”) we, PunchIn (“we”, “us”, or “our”) collect from the users (“Users”) of our Website, <a href="https://www.2vm.co.uk">PunchIn.com</a> (“Website”), how the Information may be shared with third parties, and what controls Users have regarding their Information. The Privacy Policy also sets forth our policies and practices with respect to any data received from our Customers (“Customers”) or Customer employees (“Customer Employees”) who use our products or services.</p>
                  <p>&nbsp;</p>
                  <p>Please review the following Privacy Policy carefully to understand our practices with respect to your Information. We may amend this Privacy Policy from time to time as we change or expand our Website. Because this Privacy Policy contains legal obligations, you should read it carefully.</p>
                  <p>By using or accessing the Website, you signify that you have read, understand, and agreed to be bound by this Privacy Policy. You also are bound by the terms of use (“Terms of Use”), which may be viewed by clicking <a href="https://www.2vm.co.uk/termsandconditions">here</a>. If you are a User of our products, you are also bound by the terms of use applicable to such products.</p>
                  <h4><strong>What Type of Information We Collect</strong></h4>
                  <p>You may enter the Website and browse its contents without submitting any Personal Information, such as your name, email address, and other information that can be used to directly identify you (“Personal Information”). Our Website also allows Users to provide contact information to us, which includes Personal Information. When you submit Personal Information on the Website, you are doing so voluntarily and giving your consent to the collection, use, and disclosure of your Personal Information as set forth in this Privacy Policy.</p>
                  <p>&nbsp;</p>
                  <p>As you navigate our Website, we may also collect information through the use of commonly used information gathering tools, such as cookies. These tools obtain standard information from your web browser, your Internet Protocol (IP) address, and the actions you take on our Website (such as the web pages viewed, and the links clicked). Although you can configure your browser to not accept cookies, this action may make certain features of the Website unavailable to you.</p>
                  <p>&nbsp;</p>
                  <p>
                    We use Google Analytics to coolect aggregate statistical information about the site usage.
                    More details on how Google makes use of cookies and  Google Analytics’ data practices can be found
                    at <a target="_blank" rel="noreferrer" href="https://support.google.com/analytics/answer/6004245" title="Google Analytics data safeguarding">https://support.google.com/analytics/answer/6004245</a>
                  </p>
                  <p>&nbsp;</p>
                  <p>We may automatically record your IP address and use it to help diagnose problems with our server, administer and improve our Website, and gather broad demographic information about Users of our Website or for other uses that we may determine from time to time. This information is used on an aggregate basis to improve the User experience on the Website. It also allows our staff to measure the number of visitors to the different sections of the Website and to make the Website more useful to Users. We do not disclose this information to third parties.</p>
                  <p>To the extent Customers choose to utilize any of our products that employ finger-scan, facial-scan, voice recognition or temperature reader technology, the collection of Customer Employee finger-scan, facial-scan, voice recognition or temperature reader data is undertaken and controlled by the Customer and may include the collection by the Customer of information to verify Customer Employee identities. This technology does not collect or store fingerprints or facial data; instead, it creates a mathematical representation of particular data about the fingertip or face in a much smaller template that is encoded for security purposes. We may collect, receive, store, or process data associated with finger-scan, facial-scan, voice recognition or temperature supplied by or about Customers and/or Customer Employees to provide products and/or services to Customers. Such data is collected pursuant to the terms of our End User License Agreement (“EULA”). We may also have a third party host such data. In that event, we only provide them information needed to perform that specific service or function.</p>
                  <p>&nbsp;</p>
                  <h4><strong>Voice Recognition Technology</strong></h4>
                  <p>Voice recognition is dormant when the time clock is not in use, meaning the time clock only listens for verbal commands once authentication is initiated. PunchIn products that support voice recognition listen for commands when triggered by a specific screen and do not store any audio recordings. </p>
                  <h4><strong>Temperature Liability Information for Devices with Temperature Readers</strong></h4>
                  <p>PunchIn is not advertising its products for use in the medical industry or for medical purposes. Our products are not intended to diagnose or exclude contagions such as COVID-19, SARS, or any other medical condition and should not be used as such. Our products are intended to serve as a first-line filter by assisting an organization and its employees in identifying those employees who may have an elevated body temperature. While our products may archive readings for reporting and audit purposes, it is solely the responsibility of the organization to communicate the use of the information to its employees, to obtain any necessary consent from its employees and to determine how the information is used.  The organization should not rely solely on our products for making such a determination. Various environmental and methodological factors can impact thermal imaging; therefore, the readings from our products should not be relied upon as the sole determinant of a person’s body temperature. Competent medical advice and further checks should be sought if there are concerns regarding an employee’s health or possible illness. PunchIn hereby disclaims all liability with respect to the conditions and limitations described in this paragraph.</p>
                  <h4><strong>How We Use the Information Collected</strong></h4>
                  <p>We use the information that you provide, including Personal Information, to perform the services requested. For example, if you provide your contact information, we will use the information provided to contact you about your interest in our services or to schedule a demonstration of our products. We also collect Personal Information in connection with the purchase of our products: however, the processing of payment card information for such purchases is conducted by a third party with whom we share only certain Personal Information of yours to enable the transaction.</p>
                  <p>We also may use Personal Information for marketing purposes. For example, we may use the information you provide us to contact you to further engage your interest in our services and to send you information regarding us. On an aggregate basis, we may also provide Personal Information to third parties for marketing, advertising, or other uses.</p>
                  <p>Whether or not you provide Personal Information, we may also collect information regarding your usage of the Website (“Usage Information”). We use Usage Information to operate and improve our Website and to analyze which of our pages or products are most popular, the browser you use, and the URLs you visited before and after using our Website.</p>
                  <p>If we collect, receive, store, or process Customer Employee data, we only do so in accordance with this Privacy Policy, or EULA, and as instructed by the Customer. Customer Employee finger-scan, facial-scan, voice recognition or temperature data is only used to provide products and/or services consistent with the Customer’s instructions and only as directed by the Customer. The Customer is able to permanently destroy any finger-scan, facial-scan, voice recognition or temperature data, even if processed by us, and is responsible for permanently destroying any finger-scan, facial-scan, voice recognition or temperature data as required by applicable law. We will permanently destroy any finger-scan, facial-scan, voice recognition or temperature data in accordance with our Biometric Data Compliance Policy, available <a href="https://www.2vm.co.uk/biometric-data-retention-policy/">HERE</a>. The Customer is also responsible for obtaining all consents and providing all notifications or information as may be required by applicable law. Customer Employees should contact the appropriate person within their employer’s organization to understand, access, change and/or control what is provided by the Customer to us.</p>
                  <h4><strong>How We Use the Do Not Track Option</strong></h4>
                  <p>Certain Internet browsers currently offer a “Do Not Track (‘DNT’) option.” However, because no common industry standard for DNT exists, we do not currently commit to responding to browsers’ DNT signals.</p>
                  <h4><strong>What We Share of Information Collected</strong></h4>
                  <p>We do not permit third parties to collect information from Users of our Website without our permission. We do not share your Personal Information with any third parties, except for the limited purposes of processing purchases of our products and operating our Website or conducting our business. We do not allow third parties to set and access cookies on your computer through our Website. We do not disclose Personal Information without your consent or pursuant to your instructions, except (i) to the extent required by law (such as in response to subpoenas, court orders, or other legal process), (ii) when we believe it is appropriate to protect and defend the rights, property, or safety of our company, our Users, or others; (iii) where we are required to disclose your Personal Information pursuant to governmental or judicial order, law or regulation to meet national security or law enforcement requirements; (iv) in accordance with our policies regarding the use of this Website, as set forth in our Terms of Use; and (v) as otherwise specified in this Privacy Policy.</p>
                  <p>We will not disclose, sell, lease, trade, or otherwise profit from Customer Employee finger-scan, facial-scan, voice recognition or temperature data. We will not otherwise disclose Customer Employee finger-scan, facial-scan, voice recognition or temperature data unless required or permitted by any state or federal law, municipal ordinance, valid warrant, or valid subpoena.</p>
                  <p>We may, however, share your data with PunchIn™ Technologies’ subsidiaries. For example, we may share your request to subscribe to newsletter regarding certain products or services with the applicable PunchIn™ Technologies’ subsidiary.</p>
                  <h4><strong>Access to personal Information</strong></h4>
                  <p>Employees of PunchIn customers should contact the appropriate person within their employer’s organization to understand, access, change, and/or control what employee information is provided by the employer to PunchIn™ Technologies so that PunchIn™ Technologies may deliver its products and services to the employer.</p>
                  <h4><strong>How We Link to Other Websites</strong></h4>
                  <p>This Website may contain links to other websites operated by third parties. We are not responsible for the privacy practices of the contents of such websites. Except as set forth in this policy, we do not share your Personal Information with those websites.</p>
                  <h4><strong>How We Handle Security of Personal Information</strong></h4>
                  <p>We have taken commercially reasonable steps to ensure the security and confidentiality of Personal Information against loss, misuse, unauthorized disclosure, alteration, and destruction. Please remember that no transmission of data over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, we cannot and do not guarantee the complete security of Personal Information you provide to us through your use of this Website.<br />
                     All Customer Employee data obtained through finger-scan, facial-scan, voice recognition or temperature reader technology is protected from disclosure using the reasonable standard of care within the industry.  Our commitment is that this data will be protected to the same level that the Company stores, transmits, and protects other confidential and sensitive company information.
                  </p>

                  <h4><strong>Who Can Use Our Website</strong></h4>
                  <p>This Website is targeted to adult consumers. We do not knowingly collect or solicit Personal Information directly from anyone under 13 years of age. If you are under age 13, do not send any information about yourself to us, including your name, address, telephone number, or email address.</p>
                  <h4><strong>How Users Can Opt Out</strong></h4>
                  <p>If you provide your Personal Information to us on the Website, we may contact you regarding your interest in our products. If you later decide to remove your information from our database, you can do so by using the Unsubscribe link at the bottom of your emails.</p>
                  <h4><strong>How to Contact Us</strong></h4>
                  <p>If you have any questions about our Privacy Policy, please contact us with “Privacy” in the subject line of your email at:</p>
                  <p></p>
                  <p><a href="mailto:info@PunchIntech.com">info@punch-in.co.uk</a></p>
                  <p></p>
                  <p>Or write to us at:</p>
                  <Card className="my-2"><CompanyAddress /></Card>
               </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </LayoutSecondary>
);

export default Success;
